/* Core CSS required for Ionic components to work properly */
@import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/react/css/padding.css";
@import "@ionic/react/css/float-elements.css";
@import "@ionic/react/css/text-alignment.css";
@import "@ionic/react/css/text-transformation.css";
@import "@ionic/react/css/flex-utils.css";
@import "@ionic/react/css/display.css";

/* Lib Styles */
@import "swiper/swiper-bundle.min.css";
@import "react-datepicker/dist/react-datepicker.css";

/* Custom Theme */
@import "./theme/variables.css";

/* Fonts */
@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"), url(../assets/fonts/Aeonik-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"), url(../assets/fonts/Aeonik-Medium.ttf) format("truetype");
  font-weight: 500;
}
